$breakpoints: (
	'xxl': 1400px,
);

@mixin xxl {
	@media (min-width: map-get($breakpoints, 'xxl')) {
		@content;
	}
}

@include xxl {
	.navbar {
		span {
			font-size: 22px;
		}
		.main_nav_link {
			font-size: 14px;
		}
	}
	.describe_section {
		height: 91vh;
		display: flex;
		justify-content: center;
		align-items: center;
		.describe_main_text {
			.describe_section_header {
				font-size: 33px;
			}
			h3 {
				font-size: 33px;
			}
		}
	}
	.Form {
		.form_card {
			.Form_img_title {
				font-size: 25px;
			}
		}
	}

	.FaQ {
		margin-bottom: 0;
		padding-bottom: 5%;
		.acording_list_item {
			flex-direction: row;
			.faq_img {
				display: flex;
				width: 100%;
				margin-left: 5%;
			}
		}
	}
	footer {
		.footer_p {
			margin-top: 0;
			padding-top: 0%;
		}
	}
}

//.describe_section .describe_main_text .describe_section_header 33
