.howItworks {
	height: 68vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h1 {
		margin-bottom: 30px;
	}

	.howItworkFaIcon {
		display: block;
		font-size: 40px;
		margin: 0 auto;
		margin-top: 30px;
	}

	.howItworks_list {
		margin-top: 80px;
		margin-bottom: 20px;

		.howItWorks_fa_icon {
			background-color: #4380fe;
			color: #f9f8f8;
			padding: 10px;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			margin-left: 60%;
		}

		.howItWorks_descripe_text {
			margin-bottom: 20px;
			font-size: 20px;
			color: black;
		}
	}
	.describe_button_buy {
		padding: 13px;
		background-color: #4380fe;
		border: none;
		border-radius: 8px;

		width: 300px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.5s ease-in-out;
		a {
			text-decoration: none;
			color: #f9f8f8;
			font-size: 17px;
			text-transform: uppercase;
		}
	}
	.describe_button_buy:hover {
		background-color: #f9f8f8;
		border: 2px solid black;
		a {
			color: #4380fe;
		}
	}
}
