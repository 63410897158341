.navbar {
	z-index: 10;
}
.navbarBrand {
	width: 80px;
	height: 80px;
}
nav {
	.main_nav_link {
		margin-right: 40px;
		font-size: 20px;
		font-weight: 550;
		text-transform: uppercase;
		color: black;

		transition: 0.5s ease-in-out;
		&:hover {
			color: #4380fe;
		}
	}
}
.main_nav_button_info {
	padding: 20px;
	font-size: 20px;
	text-transform: uppercase;
	background-color: #4380fe;
	border: none;
	border-radius: 4%;
	a {
		text-decoration: none;
		color: #fefefe;
	}
	a:hover {
		color: #fefefe;
	}
}
