.FaQ {
    height: 100vh;
    background-color: #fdfcf5;
    
    h1{
        padding: 30px;
        color: #4381ff;
        font-weight: 800;
    }

    .FaQ_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        button{
            width: 400px;
            background-color: #072892;
            padding: 30px;
            height: 125px;
            font-size: 16px;
        }
    }
    .faq_img{
        border-radius: 2%;
    }
    .faq_p {
        text-align: start;
        font-size: 20px;
        padding: 20px;
        font-weight: bold;
    }
    .faq_icon{
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        font-family: 'Times New Roman', Times, serif;
        margin-left: 6%;
        .faq_color{
            color: #4381ff;
        }
    }
    .acording_list_item{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 30px;
        button{
            
            background-color: #4381ff;
            color: #f9f8f8;
            font-size: 18px;
            font-weight: bold;
        }
    }
}