.Form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10vh;
	margin-bottom: 36vh;
	.card-group {
		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
	.form_card {
		

		.Form_img_title,
		p {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-size: 20px;
			text-transform: uppercase;
			font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
			font-weight: 600;
		}
		.form_img_button {
			display: block;
			margin: 0 auto;
			padding: 20px;
			border: none;
			background-color: #4380fe;
			color: #fefefe;
			text-transform: uppercase;
			border-radius: 5%;
			font-size: 14px;
			font-weight: bold;
			font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
			margin-top: 10px;
			transition: color 0.5s ease-in-out;
			width: 100%;
		}
		.form_img_button:hover {
			background-color: #fefefe;
			color: #4380fe;
			border: 2px solid black;
			border-radius: 8px;
		}
	}
}
