$breakpoints: (
	'xl': 1200px,
);

@mixin xl {
	@media (min-width: map-get($breakpoints, 'xl')) {
		@content;
	}
}

@include xl {
	.navbar {
		.main_nav_button_info {
			display: block;
		}
	}
	.describe_section {
		.describe_secend_container {
			.describe_img {
				width: 400px;
				margin-top: 7%;
				margin-left: 17%;
			}
		}
	}
	.aboutUs {
		.aboutUs_secend_container {
			.item_aboutUs_img {
				.aboutUs_img {
					margin-top: 0%;
					margin-right: 0%;
					margin-left: 23%;
				}
			}
		}
	}
}
