.describe_section {
	background-color: #4380fe;
	.describe_main_text {
		margin-top: 10%;
		.describe_section_header {
			font-weight: bold;
		}
		p {
			color: white;
		}
		h3 {
			color: #f9f8f8;
			font-weight: bold;
		}
		.describe_button_buy {
			padding: 13px;
			background-color: #062992;
			border: none;
			border-radius: 8px;
			margin-top: 6%;
			width: 300px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.5s ease-in-out;
			a {
				text-decoration: none;
				color: #f9f8f8;
				font-size: 17px;
				text-transform: uppercase;
			}
		}
		.describe_button_buy:hover {
			background-color: #f9f8f8;
			border: 2px solid black;
			border-radius: 8px;
			a {
				color: #062893;
				font-weight: 700;
			}
		}
	}
}
