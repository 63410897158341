.form_validate {
	height: 190vh;
	display: none;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	.Personal_data {
		margin-top: 30px;
		background-color: lightgray;
		padding: 25px;
		box-shadow: 0px 9px 19px -7px rgba(66, 68, 90, 0.45);
		border-radius: 0.5%;

		.form_data_title {
			
			font-weight: 800;
			font-size: 20px;
		}
		.form_validate_p {
			font-size: 12px;
			font-weight: 400;
			color: black;
		}
		.form_data {
			font-weight: 500;
			font-size: 16px;
		}
	}
}
