.whyImportant {
	height: 65vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 26vh;

	h1 {
		text-align: center;
		margin-bottom: 30px;
	}
	.WhyImportant_list {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;

		.card {
			background-color: #e6e9ea;
			border-top: none;
			border-left: none;
			border-bottom-right-radius: 2px solid gray;
			margin-right: 10px;
			.faIcons {
				margin-top: 70px;
				font-size: 50px;
				color: #4380fe;
			}

			.card-text {
				font-size: 20px;
				font-weight: 800;
				text-align: center;
				color: black;
			}
		}
	}
	.describe_button_buy {
		padding: 13px;
		background-color: #4380fe;
		border: none;
		border-radius: 8px;
		margin-top: 6%;
		width: 300px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.5s ease-in-out;
		a {
			text-decoration: none;
			color: #f9f8f8;
			font-size: 17px;
			text-transform: uppercase;
		}
	}
	.describe_button_buy:hover {
		background-color: #f9f8f8;
		border: 2px solid black;
		a {
			color: #4380fe;
		}
	}
}
