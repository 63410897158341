$breakpoints: (
	'md': 768px,
	'lg': 992px,
);

@mixin md {
	@media (min-width: map-get($breakpoints, 'md')) {
		@content;
	}
}

@mixin lg {
	@media (min-width: map-get($breakpoints, 'lg')) {
		@content;
	}
}

@media (min-width: 750px) {
	.howItworks {
		.howItworks_list {
			.howItWorks_fa_icon {
				margin-left: 46%;
			}
		}
	}
	.Form {
		.form_card {
			p {
				text-align: center;
			}
			.Form_img_title {
				text-align: center;
			}
		}
	}
	.aboutUs {
		.aboutUs_secend_container {
			.aboutus_left {
				margin-left: 0;
				width: 100%;
			}
		}
	}
}

@include md {
	.navbar {
		.navbarBrand {
			width: 60px;
			height: 60px;
		}
	}
	.describe_section {
		.describe_main_text {
			margin-top: 7%;
			p {
				font-size: 19px;
			}

			h3 {
				font-size: 25px;
			}

			.describe_section_header {
				font-size: 25px;
			}
			.describe_button_buy {
				.describe_btn {
					font-size: 18px;
				}
			}
		}
	}
	.whyImportant {
		.WhyImportant_list {
			.card {
				.card-text {
					font-size: 20px;
				}
				.faIcons {
					font-size: 40px;
				}
			}
		}
	}

	.howItworks {
		.howItworks_list {
			margin-top: 9%;
			margin-bottom: 9%;
			margin-left: 3%;
			.howItWorks_descripe_text {
				font-size: 17px;
			}
			.howItWorks_fa_icon {
				width: 20px;
				height: 20px;
				margin-left: 0;
			}
		}
	}
	.Form {
		height: 100%;
		.form_card {
			margin-right: 3%;
			p {
				font-size: 17px;
			}
			.form_img_button {
				padding: 12px;
				font-size: 15px;
			}
			.Form_img_title {
				font-size: 19px;
			}
		}
	}
	.form_validate {
		height: 100%;
		margin-top: 0;
		.Personal_data {
			border-radius: 0.5%;
		}
	}
	.aboutUs {
		height: auto;
		.aboutUs_secend_container {
			.aboutus_left {
				text-align: start;
				margin-top: 15%;
				margin-left: 0;
				margin-bottom: 19%;
				h1 {
					font-weight: bold;
				}
			}
			.item_aboutUs_img {
				.aboutUs_img {
					display: block;
					width: 300px;
				}
			}
		}
	}
	.FaQ {
		.faq_icon {
			span {
				font-size: 20px;
			}
		}
		.faq_p {
			font-size: 22px;
		}
		.acording_list_item {
			button {
				font-size: 18px;
			}
		}
	}
	footer {
		padding: 10px;
		.footer_p {
			width: 100%;
			margin: 0;
			font-size: 18px;
			padding-top: 3%;
			margin-top: -4%;
		}
	}
}

//--------------start--820px-----------//

@media (min-width: 820px) {
	.navbar {
		span {
			font-size: 20px;
		}
	}
	.describe_section {
		.describe_main_text {
			margin-top: 6%;
			p {
				font-size: 20px;
			}
			h3 {
				font-size: 29px;
			}
			.describe_button_buy {
				.describe_btn {
					font-size: 21px;
				}
			}
			.describe_section_header {
				font-size: 29px;
			}
		}
	}
	.whyImportant {
		.WhyImportant_list {
			.card {
				.card-text {
					font-size: 22px;
				}
			}
		}
	}

	.howItworks {
		.howItworks_list {
			.howItWorks_descripe_text {
				font-size: 20px;
			}
			.howItWorks_fa_icon {
				margin-top: 2%;
				width: 25px;
				height: 25px;
			}
		}
	}
	.Form {
		.form_card {
			.Form_img_title {
				font-size: 21px;
			}
		}
	}

	.aboutUs {
		.aboutUs_secend_container {
			.aboutus_left {
			}
			.item_aboutUs_img {
				.aboutUs_img {
					width: 328px;
				}
			}
		}
	}
}

//--------------end--820px-----------//

@include lg {
	.navbar {
		.main_nav_link {
			font-size: 16px;
		}
	}
	.describe_section {
		.describe_secend_container {
			.describe_img {
				display: block;
				margin-left: 0;
				width: 370px;
				margin-top: 10%;
			}
		}
		.describe_main_text {
			margin-top: 10%;
			.describe_section_header {
				font-weight: bold;
			}
			p {
				color: white;
			}
			h3 {
				color: #f9f8f8;
				font-weight: bold;
			}
			.describe_button_buy {
				padding: 15px;
				background-color: #062992;
				border: none;
				border-radius: 3%;
				margin-top: 2%;
				a {
					text-decoration: none;
					color: #f9f8f8;
					font-size: 17px;
					text-transform: uppercase;
				}
			}
		}
	}
	.whyImportant {
		h1 {
			margin-top: 6%;
		}
		.WhyImportant_list {
			flex-direction: row;
			.card {
				width: 20%;
				height: 222px;
				margin-right: 2%;
				.faIcons {
					margin-top: 14%;
				}
				.card-text {
					font-size: 18px;
				}
			}
		}
	}

	.howItworks {
		.howItworks_list {
			.howItWorks_descripe_text {
				font-size: 19px;
			}
		}
	}
	.Form {
		height: 100%;
		.form_card {
			.Form_img_title {
				font-size: 21px;
			}
			p {
				font-size: 19px;
			}
		}
	}
	.form_validate {
		height: 100%;
		margin-top: 0;
	}
	.aboutUs {
		.aboutUs_secend_container {
			.aboutus_left {
				// margin-top: 34%;
				h1 {
					margin-bottom: 10%;
				}
				p {
					font-size: 19px;
				}
			}
			.item_aboutUs_img {
				.aboutUs_img {
					// margin-top: 24%;
					display: block;
					width: 500px;
					margin-right: 5%;
				}
			}
		}
	}
	// .FaQ .acording_list_item button
	.FaQ {
		.faq_p {
			font-size: 24px;
		}
		.faq_icon {
			span {
				font-size: 23px;
			}
		}
		.acording_list_item {
			button {
				font-size: 19px;
			}
		}
	}
}

@media (min-width: 1024px) {
	.describe_section {
	}
	.howItworks {
		.howItworks_list {
			.howItWorks_fa_icon {
				width: 30px;
				height: 30px;
			}
			.howItWorks_descripe_text {
				font-size: 21px;
			}
		}
	}
	.aboutUs {
		.aboutUs_secend_container {
			.aboutus_left {
				p {
					font-size: 21px;
				}
			}
		}
	}
	.FaQ {
		.acording_list_item {
			button {
				font-size: 23px;
			}
		}
	}
	footer {
		.footer_p {
			font-size: 21px;
		}
	}
}
