@import './Components/Nav/_MainNav.scss';
@import '~bootstrap/scss/bootstrap';
@import 'Components/Sections/DescribeSection/Describe';
@import 'Components/Sections/WhyImportant/WhyImportant';
@import 'Components/Sections/HowItWorks/HowItWorks';
@import 'Components/Sections/Chose_form_option/Form.scss';
@import 'Components/Sections/Form_Validate/Form_Validate';
@import 'Components/Sections/FAQ/FAQ';
@import 'Components/Sections/AboutUs/AboutUs';
@import 'Components/Sections/FooterSection/Footer_Section';
@import 'Components/RWD/mobile';
@import 'Components/RWD/tablet';
@import 'Components/RWD/laptop';
@import 'Components/RWD/screen-xxl';
@import 'Components/Sections/Regulations/Regulations';
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.underline {
	width: 100px;
	height: 5px;
	background-color: #4380fe;
	border-radius: 5%;
	margin: 0 auto;
}

@include media-breakpoint-up(xxl) {
	.whyImportant {
		height: 65vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 26vh;
	}
	.howItworks {
		height: 68vh;
		margin-bottom: 13vh;
		margin-top: 13vh;
	}
}

@include media-breakpoint-down(xxl) {
	nav {
		.main_nav_link {
			margin-right: 22px;
		}
	}
	.describe_section {
		height: 92vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.whyImportant {
		height: 66vh;
	}
}

@include media-breakpoint-between(xl, xxl) {
	.Form {
		margin-top: 14vh;
	}
}

@include media-breakpoint-between(md, xxl) {
	.howItworks {
		height: 68vh;
		margin-bottom: 13vh;
		margin-top: 13vh;
	}
}

@include media-breakpoint-between(md, lg) {
	.whyImportant {
		height: 89vh;
		margin-bottom: 3vh;
	}
}

@include media-breakpoint-down(xl) {
	.Form {
		margin-top: 15.5vh;
	}
}

@include media-breakpoint-down(md) {
	.describe_section {
		height: 90vh;
	}
	.whyImportant {
		height: 89vh;
		margin-bottom: 1vh;
	}
	.howItworks {
		height: 86vh;
		margin-bottom: 4vh;
	}
}
