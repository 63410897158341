$breakpoints: (
	'xs': 320px,
	'xxl': 1400px,
);

@mixin xs {
	@media (min-width: map-get($breakpoints, 'xs')) {
		@content;
	}
}

/*
	A set of rules creating a cellular view
	
	Start of the code
*/

@include xs {
	.navbar {
		.navbarBrand {
			width: 40px;
			height: 40px;
		}
		span {
			font-size: 15px;
		}
		.main_nav_link {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 15px;
		}

		.main_nav_button_info {
			display: none;
		}
	}
}

@include xs {
	.describe_section {
		// height: 100%;
		.describe_secend_container {
			.describe_img {
				display: none;
			}
		}
		.describe_main_text {
			margin-bottom: 10%;
			.describe_section_header {
				font-size: 22px;
				font-weight: bold;
				color: #f9f8f8;
			}
			h3 {
				font-size: 20px;
			}
			.describe_button_buy {
				padding: 10px;
				.describe_btn {
					font-size: 15px;
				}
			}
		}
	}
}

@include xs {
	.whyImportant {
		// height: auto;
		.WhyImportant_list {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.card {
				margin: 0 0 10px 0;
				width: 100%;
				.faIcons {
					margin-top: 20px;
					font-size: 30px;
				}
				.card-text {
					font-size: 15px;
				}
			}
		}
	}
}
@include xs {
	.howItworks {
		.howItworkFaIcon {
			font-size: 20px;
		}
		.howItworks_list {
			margin-top: 15%;
			.howItWorks_fa_icon {
				width: 15px;
				height: 15px;
				margin-left: 44%;
				margin-bottom: 5%;
			}
			.howItWorks_descripe_text {
				font-size: 16px;
				text-align: center;
			}
		}
	}
}

@include xs {
	.Form {
		height: auto;
		.form_card {
			margin-right: 0;
			.Form_img_title,
			p {
				font-size: 16px;
			}
			.form_img_button {
				padding: 10px;
				font-size: 13px;
			}
		}
	}
}

@include xs {
	.form_validate {
		height: auto;
		.Personal_data {
			border-radius: 0%;
		}
	}
}

@include xs {
	.aboutUs {
		height: auto;
		margin-top: 8%;
		.aboutUs_secend_container {
			display: block;
			.aboutus_left {
				margin-top: 10%;
				text-align: center;
				h1 {
					font-weight: bold;
					margin-bottom: 5%;
				}
				p {
					font-size: 15px;
				}
			}
			.aboutUs_img {
				display: none;
			}
		}
	}
}

@include xs {
	.FaQ {
		height: 100%;
		margin-bottom: 4%;
		button {
			width: 100%;
			height: auto;
		}
		.faq_img {
			display: none;
		}
		.faq_p {
			text-align: center;
		}
		.faq_icon {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			span {
				font-size: 14px;
				text-align: center;
				margin-right: 10%;
			}
		}
		.acording_list_item {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-top: 30px;
			width: 100%;
			.faq_accordion {
				width: 100%;
			}
			button {
				font-size: 16px;
			}
		}
	}
}

@include xs {
	footer {
		height: 100%;
		.footer_p {
			display: block;
			width: 207px;
			margin: 0 auto;
			font-size: 16px;
		}
	}
}

/*
	A set of rules creating a cellular view
	
	End of the code
*/

//------------start-375px-----------------//
@media (min-width: 375px) {
	.navbar {
		span {
			font-size: 17px;
		}
	}
	.describe_section {
		.describe_secend_container {
			.describe_main_text {
				p {
					font-size: 16px;
				}

				h3 {
					font-size: 22px;
				}

				.describe_section_header {
					font-size: 22px;
				}
			}
		}
	}

	.howItworks {
		.howItworks_list {
			.howItWorks_fa_icon {
				margin-left: 45%;
			}
		}
	}
	.FaQ {
		.faq_icon {
			span {
				font-size: 16px;
			}
		}
	}
}
//------------end-375px-----------------//

//------------start-414px-----------------//
@media (min-width: 414px) {
	.whyImportant {
		.WhyImportant_list {
			.card {
				.card-text {
					font-size: 17px;
				}
				.faIcons {
					font-size: 35px;
				}
			}
		}
	}
	.howItworks {
		.howItworks_list {
			.howItWorks_descripe_text {
				font-size: 17px;
			}
			.howItWorks_fa_icon {
				width: 20px;
				height: 20px;
			}
		}
	}
	.Form {
		h3 {
			padding: 0 10px;
		}
		.form_card {
			.form_img_button {
				padding: 12px;
				font-size: 15px;
			}
			.Form_img_title {
				font-size: 16px;
			}
		}
	}
	.FaQ {
		.faq_icon {
			span {
				font-size: 18px;
			}
		}
	}
}
//------------end-414px-----------------//
//==================================================//

@media (min-width: 576px) {
	.Form {
		.form_card {
			.Form_img_title {
				text-align: center;
			}
			.card-text {
				text-align: center;
			}
		}
	}
}
