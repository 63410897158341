footer {
	height: 5vh;
	background-color: #072892;
	color: #f9f8f8;
	.footer_p {
		font-size: 15px;
		// font-weight: bold;
		text-align: center;
		padding-top: 12px;
		font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	}
}
